import ReactDOM from "react-dom/client"
import "./index.css"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import { BrowserRouter } from "react-router-dom"
import { MainController } from "./controllers/main"
import { Shadows, ThemeProvider, createTheme } from "@mui/material"
import { colors } from "./services/config/colors"

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(
  <BrowserRouter>
    <ThemeProvider
      theme={createTheme({
        palette: {
          mode: "light",
          primary: {
            main: colors.primary,
          },
          secondary: {
            main: colors.secondary,
          },
        },
        shadows: Array(25).fill("none") as Shadows,
        breakpoints: {
          values: {
            xs: 0,
            sm: 500,
            md: 700,
            lg: 1024,
            xl: 1280,
          },
        },
      })}
    >
      <MainController>
        <div
          className="center"
          style={{
            width: "100%",
            height: "100vh",
            minHeight: "100vh",
          }}
        >
          <App />
        </div>
      </MainController>
    </ThemeProvider>
  </BrowserRouter>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
