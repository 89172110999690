import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react"
import { useSearchParams } from "react-router-dom"
import { v4 as uuid } from "uuid"
import { post } from "../services/api/api"

interface MainContextInterface {
  loading: boolean
  sessionId: string
  fromApp: boolean
  typeformId: string
  typeformCompleted: boolean
  setTypeformCompleted: Dispatch<SetStateAction<boolean>>
}

const MainContext = createContext<MainContextInterface>({
  loading: true,
  sessionId: "",
  fromApp: false,
  typeformId: "",
  typeformCompleted: false,
  setTypeformCompleted: () => {},
})

const MainController = ({ children }: { children: ReactNode }) => {
  const [searchParams] = useSearchParams()

  // loadings
  const [loading, setLoading] = useState<boolean>(true)

  // states
  const sessionId: string = useMemo(() => uuid(), [])
  const [fromApp, setFromApp] = useState<boolean>(false)
  const [typeformId, setTypeformId] = useState<string>("")
  const [typeformCompleted, setTypeformCompleted] = useState<boolean>(false)

  // signin
  const signIn = async (key: string, iv: string) => {
    try {
      const { data } = await post(
        "/aes/signin",
        undefined,
        {
          key: key,
          iv: iv,
        },
        false
      )

      // set access token in local storage
      localStorage.setItem("accessToken", data.AccessToken)

      setLoading(false)
    } catch (e) {
      console.log("/aes/signin error", e)
    }
  }

  // initial check
  useEffect(() => {
    // get key and iv from query params
    const key = searchParams.get("key")
    const iv = searchParams.get("iv")

    if (key && iv) {
      setFromApp(true)
      setTypeformId(process.env.REACT_APP_TYPEFORM_ID_APP!)
      signIn(key, iv)
    } else {
      setTypeformId(process.env.REACT_APP_TYPEFORM_ID_TOTEM!)
      setLoading(false)
    }
  }, [])

  return (
    <MainContext.Provider
      value={{
        loading,
        sessionId,
        fromApp,
        typeformId,
        typeformCompleted,
        setTypeformCompleted,
      }}
    >
      {children}
    </MainContext.Provider>
  )
}
export { MainController, MainContext }
