import { useContext } from "react"
import { MainContext } from "../controllers/main"
import Qr from "../components/Qr"
import TypeformWidget from "../components/TypeformWidget"

const Landing = () => {
  const {
    sessionId,
    fromApp,
    typeformId,
    typeformCompleted,
    setTypeformCompleted,
  } = useContext(MainContext)

  if (typeformCompleted && !fromApp) {
    return <Qr sessionId={sessionId} />
  }

  return (
    <TypeformWidget
      sessionId={sessionId}
      typeformId={typeformId}
      onSubmit={() => {
        if (fromApp) {
          window.open(process.env.REACT_APP_QR_REDIRECT_URL + sessionId)
        } else {
          setTypeformCompleted(true)
        }
      }}
    />
  )
}

export default Landing
