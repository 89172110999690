import { Widget } from "@typeform/embed-react"

const TypeformWidget = ({
  sessionId,
  typeformId,
  onSubmit,
}: {
  sessionId: string
  typeformId: string
  onSubmit: () => void
}) => {
  return (
    <Widget
      id={typeformId}
      style={{
        width: "100%",
        height: "100%",
      }}
      onSubmit={onSubmit}
      hidden={{
        session_id: sessionId,
      }}
      inlineOnMobile
    />
  )
}

export default TypeformWidget
