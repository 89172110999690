import { Stack } from "@mui/material"
import QRCode from "react-qr-code"
import Button from "./Button"

const Qr = ({ sessionId }: { sessionId: string }) => {
  return (
    <Stack alignItems="center" gap={4}>
      <QRCode
        value={process.env.REACT_APP_QR_REDIRECT_URL! + sessionId}
        size={250}
      />
      <Button
        title="Ricomincia / Restart"
        onClick={() => {
          window.location.reload()
        }}
      >
        Ricomincia / Restart
      </Button>
    </Stack>
  )
}

export default Qr
