import awLogo from "../assets/images/aw-logo.svg"
import { LinearProgress, Stack } from "@mui/material"
import { colors } from "../services/config/colors"

const Loading = () => {
  return (
    <Stack
      className="center"
      style={{
        width: "100%",
        height: "100%",
        backgroundColor: colors.backgroundWhite,
      }}
    >
      <img src={awLogo} style={{ width: "38%", maxWidth: 165 }} alt="AWorld" />
      <LinearProgress
        style={{
          width: "34%",
          maxWidth: 157,
          marginTop: 16,
          borderRadius: 100,
        }}
      />
    </Stack>
  )
}

export default Loading
