import axios from "axios"

// .env variables
const baseUrl = process.env.REACT_APP_API_URL

// get method
export const get = async (path: string, headers?: object, withAuth = true) => {
  console.log(`GET ${baseUrl}` + path)

  const config = {
    method: "get",
    url: baseUrl + path,
    headers: withAuth
      ? {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        }
      : {
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        },
  }

  if (headers) {
    config.headers = {
      ...config.headers,
      ...headers,
    }
  }

  return axios(config)
}

// post method
export const post = async (
  path: string,
  body?: object,
  headers?: object,
  withAuth = true
) => {
  if (body) {
    console.log(`POST ${baseUrl}` + path, body)
  } else {
    console.log(`POST ${baseUrl}` + path)
  }

  const config = {
    method: "post",
    url: baseUrl + path,
    headers: withAuth
      ? {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        }
      : {},
    data: body,
  }

  if (headers) {
    config.headers = {
      ...config.headers,
      ...headers,
    }
  }

  return axios(config)
}

// put method
export const put = async (path: string, body?: object, headers?: object) => {
  if (body) {
    console.log(`PUT ${baseUrl}` + path, body)
  } else {
    console.log(`PUT ${baseUrl}` + path)
  }

  const config = {
    method: "put",
    url: baseUrl + path,
    headers: {
      Authorization: "Bearer " + localStorage.getItem("accessToken"),
    },
    data: body,
  }

  if (headers) {
    config.headers = {
      ...config.headers,
      ...headers,
    }
  }

  return axios(config)
}
