import { useContext } from "react"
import { MainContext } from "./controllers/main"
import Landing from "./views/landing"
import Loading from "./views/loading"

const App = () => {
  const { loading } = useContext(MainContext)

  if (loading) {
    return <Loading />
  }

  return <Landing />
}

export default App
